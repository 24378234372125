/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Dialog } from '@fluentui/react-northstar';
import { app } from '@microsoft/teams-js';
import { useSelector } from 'react-redux';

// Components
import OverlayLoader from 'COMPONENTS/overlayLoader/OverlayLoader';
import LanguageSelectionDropdown from 'FEATURES/tab/inMeeting/languageSelectionDropdown/LanguageSelectionDropdown';

// Services
import logger from 'SERVICES/logger';
import { logAuditEvent } from 'SERVICES/auditLogger';

// Utils
import { selectLanguageForCaptions } from 'UTILS/languagesUtils';
import { getTeamsContext } from 'UTILS/teamsUtils';

// Store
import { teamsMeetingSelector } from 'STORE/teamsMeetingSlice';

// Hooks
import useInMeeting from 'FEATURES/tab/inMeeting/hooks/useInMeeting';

// Constants
import { SNACKBAR_DURATION } from 'CONSTANTS/enum';
import { AUDIT_EVENT_TYPE } from 'CONSTANTS/apiConstants';

const InMeeting = () => {
  const {
    t,
    loading,
    selectedLanguage,
    showCountDownTimer,
    interpreterMeetings,
    setUnsupportedPopup,
    showUnsupportedPopup,
    apiFetchComplete,
    handleLanguageSelection,
  } = useInMeeting();

  const [ccOn, setCcOn] = useState(false);
  const [captionData, setCaptionData] = useState<any>(false);

  // Max 21 seconds it take to load
  const [timer, setTimer] = useState(21);
  const teamsMeetingDetail = useSelector(teamsMeetingSelector.teamsMeetingDetail);

  useEffect(() => {
    // TODO: HOOKS - context should be immediately available via useTeams hook
    // but its value is available after a delay, so sgetTeamsContext is used directly

    (async () => {
      const context: app.Context = (await getTeamsContext()) as app.Context;
      logAuditEvent({
        Timestamp: new Date(),
        Meeting_ID: context.meeting?.id,
        Event_type: AUDIT_EVENT_TYPE.msTeamsLogin,
        Field_1: context.user?.id,
        Field_3: context.user?.displayName,
      });
    })();
  }, []);

  useEffect(() => {
    if (ccOn) {
      const captionLanguage = selectLanguageForCaptions(
        teamsMeetingDetail?.captionEvents,
        selectedLanguage?.value,
      );
      setCaptionData(captionLanguage);
      logger.info(`Captioning event ${captionLanguage.eventname}`);
    }

    if (!selectedLanguage) {
      setCcOn(false);
    }
  }, [selectedLanguage, ccOn]);
  // Storing in a variable so that we can clear
  let timeoutForCounter;
  if (showCountDownTimer) {
    // This timeout is to show timer on spinner
    timeoutForCounter = setTimeout(() => {
      if (timer < 31 && timer > 0) {
        setTimer(timer - 1);
      }
    }, SNACKBAR_DURATION.SHORT);
  } else if (timeoutForCounter) {
    clearTimeout(timeoutForCounter);
  }

  return (
    <>
      <Dialog
        open={showUnsupportedPopup}
        onConfirm={() => setUnsupportedPopup(false)}
        confirmButton={t('OK')}
        content={t('NOT_SUPPORTED_ON_DEVICE_MSG')}
      />
      <>
        <LanguageSelectionDropdown
          interpreterMeetings={interpreterMeetings}
          handleLanguageSelection={handleLanguageSelection}
          selectedLanguage={selectedLanguage}
          teamsMeetingDetail={teamsMeetingDetail}
          ccOn={ccOn}
          setCcOn={setCcOn}
          apiFetchComplete={apiFetchComplete}
        />
        {ccOn && (
          <iframe
            title="captions"
            id="stFrame"
            src={`//www.streamtext.net/player/?event=${captionData?.eventname}&language=${captionData?.sourcelanguageid}&chat=false&title=off&controls-transcript=off&bgc=222&fgc=fff&ff=Helvetica&fs=14`}
            style={{ width: '100%', minHeight: '65%', display: `${ccOn ? 'block' : 'none'}` }}
          />
        )}
      </>
      {showCountDownTimer && (
        <OverlayLoader label={`${t('LOADING_MEETING_PLACEHOLDER')} ${timer} ${t('SECONDS')}`} />
      )}

      {loading && <OverlayLoader label={loading} />}

      {!apiFetchComplete && <OverlayLoader label={`${t('LOAD_MEETING_INFO_LOADER_MSG')}`} />}
    </>
  );
};

export default InMeeting;
