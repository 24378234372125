import { IRoutes } from 'UTILS/teamsInterface';

export const ORIGIN = window.location.origin;

export const CONSENT_STATE_KEY = 'Graph-consent-state';

export const APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} Akouo Technologies Limited`;

export const TEAMS_ERROR = {
  SIGNIN: 'Error: unable to sign in',
  FETCH_MEETING: 'Error: unable to fetch meeting details',
  CHANGE_LANGUAGE: 'Error: unable to change Language',
  FETCH_LANGUAGE: 'Error: unable to fetch supported languages',
  SUBMIT_REQUEST: 'Error: unable to submit request',
  OPEN_CAPTIONER: 'Error: unable to open Captioner',
  FETCH_AUA_ALLOWED: 'Error: unable to fetch feature AUA status',
} as const;

export const ROUTES: Readonly<IRoutes> = {
  TAB: '/tab',
  CONFIG: '/config',
  CONSENT_POPUP: '/auth-start',
  CLOSE_POPUP: '/auth-end',
};

export const TEAMS_LANGUAGE_RESOURCE = 'teams';

export const LANGUAGE_PREFIX = {
  COMMON: { keyPrefix: 'COMMON' },
  TAB: { keyPrefix: 'TAB' },
  TAB_CONFIG: { keyPrefix: 'TAB_CONFIG' },
  PREMEETING: { keyPrefix: 'PREMEETING' },
  INMEETING: { keyPrefix: 'IN_MEETING' },
} as const;

export const CALL_TYPE = {
  FLOOR: 'floor',
};

export const ENTER_KEY_CODE = 13;

export const COPY_ELEMENT = Object.freeze({
  EXEC_COMMAND_COPY: 'copy',
  ORGANIZER_EDITABLE_PRE_FORM_CONTAINER: 'pre-meeting-container',
  ORGANIZER_EDITABLE_FORM_CONTAINER: 'form-container',
  CLIPBOARD_COPY_TEXT_SELECTOR_ID: 'copyContents',
});

export const LANGUAGES_SELECTED = 2;
export const AKOUO_CONNECT_URL = 'https://akouo.io/connect-akouo';
export const AKOUO_SUPPORT_URL = 'https://akouo.io/support';
