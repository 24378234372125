import { Text, Flex, FlexItem, Dropdown, Tooltip, ChatIcon } from '@fluentui/react-northstar';
import { app } from '@microsoft/teams-js';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Constants
import { ENTER_KEY_CODE, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Utils
import { ILanguageDropdown } from 'UTILS/teamsInterface';

// Styles
import './LanguageSelectionDropdown.scss';
import AkouoSupport from 'COMPONENTS/AkouoSupport';

const LanguageSelectionDropdown = ({
  interpreterMeetings,
  handleLanguageSelection,
  selectedLanguage,
  teamsMeetingDetail,
  ccOn,
  setCcOn,
  apiFetchComplete,
}: ILanguageDropdown) => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);

  const chatOnClick = () => {
    /* 
      This method will be convert joinUrl from response object into required chat Url.
      app.openLink() method redirects to conversation of current meeting.
    */
    const chatLink = teamsMeetingDetail.joinUrl.replace('meetup-join', 'chat');
    app.openLink(chatLink);
  };

  const languageDropdown = interpreterMeetings.map((lang: any) => {
    const obj = {
      ...lang,
      header: lang.label, // for display
      onClick: () => handleLanguageSelection(lang),
    };
    return obj;
  });

  if (apiFetchComplete && interpreterMeetings.length === 1) {
    return (
      <Text>
        <Flex column>
          <span>{t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')}</span>
          <AkouoSupport className="pt-1" />
        </Flex>
      </Text>
    );
  }
  return (
    <Flex column className="height30">
      <FlexItem>
        <Flex gap="gap.large">
          <Text
            className="bottom-padding"
            align="start"
            size="medium"
            // width="50%"
            content={t('SIDE_PANEL_MUST_REMAIN_OPEN')}
          />
          <Tooltip
            content={t('TOOLTIP_MSG')}
            trigger={
              <div
                className="cursor-pointer chat-border"
                role="button"
                tabIndex={0}
                onClick={() => chatOnClick()}
                onKeyDown={(e) => {
                  if (e.keyCode === ENTER_KEY_CODE) {
                    e.preventDefault();
                    chatOnClick();
                  }
                }}
              >
                <ChatIcon outline size="large" />
                <Text size="small" content={t('CHAT_BTN')} />
              </div>
            }
          />
        </Flex>
      </FlexItem>
      <Text
        className="bottomPadding"
        align="center"
        size="smaller"
        content={t('INTERPRETERS_CONNECTED_WHEN_AI_IS_PARTICIPANT')}
      />
      <Flex>
        <Dropdown
          items={languageDropdown}
          id="languageSelectionDropdown"
          placeholder={`${t('DROPDOWN_PLACEHOLDER')}`}
          fluid
          value={selectedLanguage ? selectedLanguage?.label : t('DROPDOWN_PLACEHOLDER')}
        />
        {teamsMeetingDetail?.captionEvents?.length > 0 && (
          <Flex
            className={`lang-btn cc-padding ${!selectedLanguage?.label && 'disabled-cc'} ${
              ccOn && 'selected-lang-btn'
            }`}
            onClick={() => {
              setCcOn(!ccOn);
            }}
          >
            <Text>CC</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LanguageSelectionDropdown;
